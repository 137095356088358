import { default as admin4FCB2UHG0ZMeta } from "/vercel/path0/pages/admin.vue?macro=true";
import { default as categories8bHSkML6RQMeta } from "/vercel/path0/pages/categories.vue?macro=true";
import { default as confirmedoO0JfafEbqMeta } from "/vercel/path0/pages/confirmed.vue?macro=true";
import { default as deactivateWabXLajcnrMeta } from "/vercel/path0/pages/deactivate.vue?macro=true";
import { default as documentsQsTdxIaiRLMeta } from "/vercel/path0/pages/documents.vue?macro=true";
import { default as examineesUzp6vMdmpfMeta } from "/vercel/path0/pages/examinees.vue?macro=true";
import { default as examscWrWczKPNnMeta } from "/vercel/path0/pages/exams.vue?macro=true";
import { default as forgot487FlM3HxjMeta } from "/vercel/path0/pages/forgot.vue?macro=true";
import { default as indexe9Brt5DfdhMeta } from "/vercel/path0/pages/index.vue?macro=true";
import { default as logint0AWlhQgM0Meta } from "/vercel/path0/pages/login.vue?macro=true";
import { default as privacykJ3SQnmrN4Meta } from "/vercel/path0/pages/privacy.vue?macro=true";
import { default as questionspa1tRFJrodMeta } from "/vercel/path0/pages/questions.vue?macro=true";
import { default as resetjSuUNxfTnwMeta } from "/vercel/path0/pages/reset.vue?macro=true";
import { default as signupY93ILdpGpbMeta } from "/vercel/path0/pages/signup.vue?macro=true";
import { default as tagsoS3BggMdP5Meta } from "/vercel/path0/pages/tags.vue?macro=true";
import { default as topicseWG9scoFpMMeta } from "/vercel/path0/pages/topics.vue?macro=true";
export default [
  {
    name: admin4FCB2UHG0ZMeta?.name ?? "admin",
    path: admin4FCB2UHG0ZMeta?.path ?? "/admin",
    meta: admin4FCB2UHG0ZMeta || {},
    alias: admin4FCB2UHG0ZMeta?.alias || [],
    redirect: admin4FCB2UHG0ZMeta?.redirect || undefined,
    component: () => import("/vercel/path0/pages/admin.vue").then(m => m.default || m)
  },
  {
    name: categories8bHSkML6RQMeta?.name ?? "categories",
    path: categories8bHSkML6RQMeta?.path ?? "/categories",
    meta: categories8bHSkML6RQMeta || {},
    alias: categories8bHSkML6RQMeta?.alias || [],
    redirect: categories8bHSkML6RQMeta?.redirect || undefined,
    component: () => import("/vercel/path0/pages/categories.vue").then(m => m.default || m)
  },
  {
    name: confirmedoO0JfafEbqMeta?.name ?? "confirmed",
    path: confirmedoO0JfafEbqMeta?.path ?? "/confirmed",
    meta: confirmedoO0JfafEbqMeta || {},
    alias: confirmedoO0JfafEbqMeta?.alias || [],
    redirect: confirmedoO0JfafEbqMeta?.redirect || undefined,
    component: () => import("/vercel/path0/pages/confirmed.vue").then(m => m.default || m)
  },
  {
    name: deactivateWabXLajcnrMeta?.name ?? "deactivate",
    path: deactivateWabXLajcnrMeta?.path ?? "/deactivate",
    meta: deactivateWabXLajcnrMeta || {},
    alias: deactivateWabXLajcnrMeta?.alias || [],
    redirect: deactivateWabXLajcnrMeta?.redirect || undefined,
    component: () => import("/vercel/path0/pages/deactivate.vue").then(m => m.default || m)
  },
  {
    name: documentsQsTdxIaiRLMeta?.name ?? "documents",
    path: documentsQsTdxIaiRLMeta?.path ?? "/documents",
    meta: documentsQsTdxIaiRLMeta || {},
    alias: documentsQsTdxIaiRLMeta?.alias || [],
    redirect: documentsQsTdxIaiRLMeta?.redirect || undefined,
    component: () => import("/vercel/path0/pages/documents.vue").then(m => m.default || m)
  },
  {
    name: examineesUzp6vMdmpfMeta?.name ?? "examinees",
    path: examineesUzp6vMdmpfMeta?.path ?? "/examinees",
    meta: examineesUzp6vMdmpfMeta || {},
    alias: examineesUzp6vMdmpfMeta?.alias || [],
    redirect: examineesUzp6vMdmpfMeta?.redirect || undefined,
    component: () => import("/vercel/path0/pages/examinees.vue").then(m => m.default || m)
  },
  {
    name: examscWrWczKPNnMeta?.name ?? "exams",
    path: examscWrWczKPNnMeta?.path ?? "/exams",
    meta: examscWrWczKPNnMeta || {},
    alias: examscWrWczKPNnMeta?.alias || [],
    redirect: examscWrWczKPNnMeta?.redirect || undefined,
    component: () => import("/vercel/path0/pages/exams.vue").then(m => m.default || m)
  },
  {
    name: forgot487FlM3HxjMeta?.name ?? "forgot",
    path: forgot487FlM3HxjMeta?.path ?? "/forgot",
    meta: forgot487FlM3HxjMeta || {},
    alias: forgot487FlM3HxjMeta?.alias || [],
    redirect: forgot487FlM3HxjMeta?.redirect || undefined,
    component: () => import("/vercel/path0/pages/forgot.vue").then(m => m.default || m)
  },
  {
    name: indexe9Brt5DfdhMeta?.name ?? "index",
    path: indexe9Brt5DfdhMeta?.path ?? "/",
    meta: indexe9Brt5DfdhMeta || {},
    alias: indexe9Brt5DfdhMeta?.alias || [],
    redirect: indexe9Brt5DfdhMeta?.redirect || undefined,
    component: () => import("/vercel/path0/pages/index.vue").then(m => m.default || m)
  },
  {
    name: logint0AWlhQgM0Meta?.name ?? "login",
    path: logint0AWlhQgM0Meta?.path ?? "/login",
    meta: logint0AWlhQgM0Meta || {},
    alias: logint0AWlhQgM0Meta?.alias || [],
    redirect: logint0AWlhQgM0Meta?.redirect || undefined,
    component: () => import("/vercel/path0/pages/login.vue").then(m => m.default || m)
  },
  {
    name: privacykJ3SQnmrN4Meta?.name ?? "privacy",
    path: privacykJ3SQnmrN4Meta?.path ?? "/privacy",
    meta: privacykJ3SQnmrN4Meta || {},
    alias: privacykJ3SQnmrN4Meta?.alias || [],
    redirect: privacykJ3SQnmrN4Meta?.redirect || undefined,
    component: () => import("/vercel/path0/pages/privacy.vue").then(m => m.default || m)
  },
  {
    name: questionspa1tRFJrodMeta?.name ?? "questions",
    path: questionspa1tRFJrodMeta?.path ?? "/questions",
    meta: questionspa1tRFJrodMeta || {},
    alias: questionspa1tRFJrodMeta?.alias || [],
    redirect: questionspa1tRFJrodMeta?.redirect || undefined,
    component: () => import("/vercel/path0/pages/questions.vue").then(m => m.default || m)
  },
  {
    name: resetjSuUNxfTnwMeta?.name ?? "reset",
    path: resetjSuUNxfTnwMeta?.path ?? "/reset",
    meta: resetjSuUNxfTnwMeta || {},
    alias: resetjSuUNxfTnwMeta?.alias || [],
    redirect: resetjSuUNxfTnwMeta?.redirect || undefined,
    component: () => import("/vercel/path0/pages/reset.vue").then(m => m.default || m)
  },
  {
    name: signupY93ILdpGpbMeta?.name ?? "signup",
    path: signupY93ILdpGpbMeta?.path ?? "/signup",
    meta: signupY93ILdpGpbMeta || {},
    alias: signupY93ILdpGpbMeta?.alias || [],
    redirect: signupY93ILdpGpbMeta?.redirect || undefined,
    component: () => import("/vercel/path0/pages/signup.vue").then(m => m.default || m)
  },
  {
    name: tagsoS3BggMdP5Meta?.name ?? "tags",
    path: tagsoS3BggMdP5Meta?.path ?? "/tags",
    meta: tagsoS3BggMdP5Meta || {},
    alias: tagsoS3BggMdP5Meta?.alias || [],
    redirect: tagsoS3BggMdP5Meta?.redirect || undefined,
    component: () => import("/vercel/path0/pages/tags.vue").then(m => m.default || m)
  },
  {
    name: topicseWG9scoFpMMeta?.name ?? "topics",
    path: topicseWG9scoFpMMeta?.path ?? "/topics",
    meta: topicseWG9scoFpMMeta || {},
    alias: topicseWG9scoFpMMeta?.alias || [],
    redirect: topicseWG9scoFpMMeta?.redirect || undefined,
    component: () => import("/vercel/path0/pages/topics.vue").then(m => m.default || m)
  }
]